import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from 'react-oidc-context';
import environments from "./utils/environments";

const oidcConfig = {
    authority: environments.authUrl+"/realms/"+environments.authRealm,
    client_id:  environments.authClient,
    redirect_uri: window.location.href,
    response_type: 'code',
    scope: 'openid profile email',
};

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <App />
    </AuthProvider>
  </React.StrictMode>
);