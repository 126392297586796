import React from 'react';
import { useAuth } from 'react-oidc-context';
import Listings from './components/Listings';
import Settings from './components/Settings';

function App() {
    const auth = useAuth();

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (auth.error) {
        return <div>Authentication Error: {auth.error.message}</div>;
    }

    if (!auth.isAuthenticated) {
        return <button onClick={() => auth.signinRedirect()}>Log in</button>;
    }

    const userID = auth.user?.profile.sub; // Benutzer-ID vom Auth-Objekt

    const userName = auth.user?.profile?.name || auth.user?.profile?.preferred_username;

    return (
        <div className="App">
            <h1>Welcome, {userName}</h1>
            <p>User ID: {auth.user?.profile?.sub}</p> {/* Zeige die UserID an */}
            <button onClick={() => auth.signoutRedirect()}>Log out</button>
            
            <Settings userID={auth.user?.profile?.sub} userName={userName} />
            <Listings userID={auth.user?.profile?.sub} userName={userName} />
        </div>
    );
}

export default App;