const initialEnv = {
    authUrl: "https://iam-dev.apps.realvation.de",
    apiUrl: "http://localhost:3000",
    authClient: 'rv-dashboard-app',
    authRealm: 'immolens',
    enableDebug: true,
    idleTime: 7200,
    idleCountdown: 60
};

// Read environment variables from browser window
const browserWindow = window || {};
// @ts-ignore
const browserWindowEnv = browserWindow['__env'] || {};

// Assign environment variables from browser window to env
// In the current implementation, properties from env.js overwrite defaults from the EnvService.
// If needed, a deep merge can be performed here to merge properties instead of overwriting them.
for (const key in browserWindowEnv) {
    // TODO Check if this is really necessary since we are already iterating from the browserWindowEnv
    if (Object.prototype.hasOwnProperty.call(browserWindowEnv, key)) {
        // @ts-ignore
        initialEnv[key] = window['__env'][key];
    }
}

export default initialEnv;